<template>
	<div class="con">
		<Form label-postion="top" :model="formData" class="design-form-view">
			<Row :gutter="32">
				<Col :span="24" v-if="projectInfo && projectInfo.projectConfigName">
					<div class="form-item-wrap">
						<FormItem label="项目类型">
							{{ projectInfo.projectConfigName }}
						</FormItem>
					</div>
				</Col>
				<Col :span="8" v-if="projectInfo && projectInfo.terminateDate">
					<div class="form-item-wrap terminate-item">
						<FormItem label="项目终止日期">
							{{ projectInfo.terminateDate }}
						</FormItem>
					</div>
				</Col>
				<Col :span="16" v-if="projectInfo && projectInfo.terminateRemark">
					<div class="form-item-wrap terminate-item">
						<FormItem label="项目终止理由">
							{{ projectInfo.terminateRemark }}
						</FormItem>
					</div>
				</Col>
				<Col
					v-for="(item, index) in list"
					:span="item.json.col || 12"
					:key="item.id"
				>
					<div class="form-item-wrap">
						<FormItem
							:label="item.json.isShowName !== 'N' ? item.json.name : ''"
						>
							<div
								v-if="item.json.type === 'base_textPrompt'"
								:style="{
									textIndent: item.json.textIndent + 'px',
									color: item.json.textColor || '#515a6e'
								}"
							>
								{{ formData[item.json.code] }}
							</div>
							<TableComp
								v-else-if="item.json.type === 'base_Table'"
								:tableItem="item.json"
								:value="formData[item.json.code]"
								:isReadonly="true"
							>
							</TableComp>
							<!--CA电子签名-->
							<div
								v-else-if="item.json.type === 'elec_sign'"
								class="elec-sign-wrap"
							>
								<Button type="primary" disabled>签名</Button>
								<div class="elec-sign-list">
									<span
										v-for="(signItem, signIndex) in formData[item.json.code]"
										style="padding: 0 20px"
										:key="signIndex"
									>
										<img
											:src="`data:image/png;base64,${signItem.img}`"
											class="sign-img"
										/>
									</span>
								</div>
							</div>
							<div v-else>
								{{ formData[item.json.code] }}
							</div>
						</FormItem>
					</div>
				</Col>
			</Row>
		</Form>
	</div>
</template>
<script>
import request from "@/utils/request"
import dictionaryApi from "@/api/setting/dictionary"
import interpreter from "@/utils/interpreter"
import TableComp from "./TableComp.vue"

const { apiGetData } = dictionaryApi

export default {
	name: "designTemplateView",
	props: ["componentList", "projectInfo"],
	data() {
		return {
			list: [],
			signatureVisible: false,
			signaturePassword: "",
			signatureLoading: false,
			selectSignatureItem: null,
			formData: {},
			formInitData: {},
			otherShowObj: {},
			signList: []
		}
	},
	components: {
		TableComp
	},
	computed: {
		isShow() {
			return this.showType === "design"
		}
	},
	watch: {
		componentList(componentList) {
			this.formData = this.getFormData()
			this.formInitData = this.getFormInitData()
			this.formatList(componentList)
		}
	},
	mounted() {
		if (this.componentList) {
			this.formData = this.getFormData()
			this.formInitData = this.getFormInitData()
			this.formatList(this.componentList)
		}
	},
	methods: {
		formatList(componentList) {
			let arr = this._.defaultsDeep([], componentList)
			// 如果是表單設計页面，需要展示全部的组件。如果不是设计页面，则根据联动展示。
			if (this.showType !== "design") {
				const formNewData = this._.defaultsDeep({}, this.formInitData) // 对象深拷贝
				for (const key in formNewData) {
					if (Array.isArray(formNewData[key])) {
						formNewData[key] = formNewData[key].join(",")
					}
				}
				arr = arr.filter(item => {
					let json = {}
					if (item.data) {
						if (typeof item.data === "object") {
							json = item.data
						} else {
							json = JSON.parse(item.data)
						}
						const result = interpreter(json.visExpr, formNewData)
						if (
							!json.visExpr ||
							Object.prototype.toString.call(result) === "[object Object]" ||
							(!!json.visExpr && !!result)
						) {
							return true
						}
					}
					return false
				})
			}
			this.$nextTick(() => {
				const allPromise = arr.map(item => this.formatFormItem(item))
				Promise.all(allPromise).then(res => {
					this.list = res
				})
			})
		},
		formatFormItem(item) {
			let json = {}
			if (typeof item.data === "object") {
				json = item.data
			} else {
				json = JSON.parse(item.data)
			}

			if (json.id) {
				return this.getDictionaryData(json, item)
			}
			if (json.isRequest === "1" || json.isRequest === "2") {
				// 需要请求接口数据
				return this.getApiList(json, item)
			}
			return new Promise(resolve => {
				resolve(Object.assign(item, { json }))
			})
		},
		// 获取需要请求接口的数据列表。
		async getApiList(json, item) {
			const res = await request(json.apiUrl)
			let optionList = []
			if (res && res.data) {
				optionList = res.data.map(leaf => ({
					value: `${leaf.id}`,
					label: leaf.name || leaf.realName
				}))
			}
			return Object.assign(item, { json: { ...json, optionList } })
		},
		async getDictionaryData(json, item) {
			const res = await apiGetData(json.id)
			let optionList = []
			if (res) {
				optionList = res.data.leaf.map(leaf => ({
					value: `${leaf.id}`,
					label: leaf.name
				}))
			}
			return Object.assign(item, { json: { ...json, optionList } })
		},
		getFormInitData() {
			const obj = {}
			if (this.componentList && this.componentList.length) {
				this.componentList.forEach(list => {
					let json = {}
					if (typeof list.data === "object") {
						json = list.data
					} else {
						json = JSON.parse(list.data)
					}
					const { dataValue } = json
					if (typeof dataValue === "number") {
						obj[json.code] = `${dataValue}`
						// } else if (json.type === 'base_Table') {
						//   obj[json.code] = [...json.tableObj.rows];
						// } else if (json.type === 'base_Select' && json.isRequest === '2') {
						//   console.log('json',json)
						//   obj[json.code] = json.userName || '';
					} else {
						obj[json.code] = dataValue
					}
				})
			}
			return obj
		},
		getFormData() {
			const obj = {}
			if (this.componentList && this.componentList.length) {
				this.componentList.forEach(list => {
					let json = {}
					if (typeof list.data === "object") {
						json = list.data
					} else {
						json = JSON.parse(list.data)
					}
					const { dataValue, optionList, types } = json
					if (typeof dataValue === "number") {
						obj[json.code] = `${dataValue}`
					} else if (json.type === "base_textPrompt") {
						// 纯文字提示信息
						obj[json.code] = json.textPrompt || "--"
						// } else if (json.type === 'base_Table') { // 表格值
						//   obj[json.code] = dataValue || [...json.tableObj.rows];
					} else if (json.type === "elec_sign") {
						// CA 电子签名
						let arr = []
						if (dataValue) {
							if (typeof dataValue === "object") {
								arr = dataValue
							} else {
								arr = JSON.parse(dataValue)
							}
						}
						obj[json.code] = arr
						// } else if (json.type === 'base_Select' && json.isRequest === '2') {
						//   console.log('form json',json)
						//   obj[json.code] = json.userName || '--';
					} else {
						obj[json.code] = dataValue || "--"
					}
					if (optionList && types) {
						if (types.includes("Radio")) {
							const newItem = optionList.find(item => item.value === dataValue)
							obj[json.code] = newItem ? newItem.label : "--"
						}
						let arr = []
						if (
							(types.includes("Checkbox") || types.includes("Checkbox_List")) &&
							dataValue
						) {
							arr = dataValue.map(
								val => optionList.find(item => item.value === val).label
							)
							obj[json.code] = arr.length ? arr.join(",") : "--"
						}
						// if (types.includes('Select')) {
						//   obj[json.code] = optionList.find(item => item.value === dataValue).label;
						// }
					}
				})
			}
			return obj
		}
	}
}
</script>

<style lang="less">
.design-form-view {
	padding: 10px 16px;
	.ivu-form-item {
		padding: 2px;
		margin-bottom: 0;
		border: 1px solid #fff;
	}
	> .ivu-row > div {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
	.ivu-form-item-label {
		float: none;
		text-align: left;
		display: block;
		font-weight: bold;
	}
	.form-item-wrap {
		/*border: 1px dashed #ddd;*/
		width: 100%;
		padding: 0 10px;
	}
	.terminate-item {
		color: red;
		.ivu-form-item-label {
			color: red;
		}
	}

	/* 电子签名*/
	.elec-sign-wrap {
		.elec-sign-list {
			margin-top: 8px;
			padding: 0 5px 5px;
			min-height: 60px;
			border: 1px solid #eee;
			border-radius: 5px;
			.sign-img {
				margin-top: 5px;
				width: 120px;
				height: 60px;
			}
		}
	}
}
</style>
